var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/index.ts
var NO_PREF = "NO_PREF";
var STACKED = "STACKED";
var FRAGMENTED = "FRAGMENTED";
var NO_REST = "NO_REST";
var assignmentPreferences = [
  NO_PREF,
  STACKED,
  FRAGMENTED,
  NO_REST
];
function isAssignmentPreference(assignment) {
  return assignmentPreferences.includes(assignment);
}
__name(isAssignmentPreference, "isAssignmentPreference");
export {
  FRAGMENTED,
  NO_PREF,
  NO_REST,
  STACKED,
  assignmentPreferences,
  isAssignmentPreference
};
