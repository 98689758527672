// src/mime.ts
var JSON = "application/json";
var CSV = "text/csv";
var ICAL = "text/calendar";
var PDF = "application/pdf";

// src/preference.ts
import { NO_PREF } from "@overbookd/preference";
var DEFAULT_PREFERENCE = {
  paperPlanning: null,
  assignment: NO_PREF,
  favoritePages: []
};
export {
  CSV,
  DEFAULT_PREFERENCE,
  ICAL,
  JSON,
  PDF
};
