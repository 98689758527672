// src/index.ts
var REGISTER_FORM_KEY = "registerForm";
var EVENT_DATE_KEY = "eventDate";
var INVITE_STAFF_LINK_KEY = "inviteStaffLink";
var INVITE_NEW_ADHERENT_KEY = "inviteNewAdherentLink";
var VOLUNTEER_BRIEFING_TIME_WINDOW_KEY = "volunteerBriefingTimeWindow";
var configurationKeys = [
  REGISTER_FORM_KEY,
  EVENT_DATE_KEY,
  INVITE_STAFF_LINK_KEY,
  INVITE_NEW_ADHERENT_KEY,
  VOLUNTEER_BRIEFING_TIME_WINDOW_KEY
];
export {
  EVENT_DATE_KEY,
  INVITE_NEW_ADHERENT_KEY,
  INVITE_STAFF_LINK_KEY,
  REGISTER_FORM_KEY,
  VOLUNTEER_BRIEFING_TIME_WINDOW_KEY,
  configurationKeys
};
