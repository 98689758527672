// src/status.ts
var DRAFT = "DRAFT";
var IN_REVIEW = "IN_REVIEW";
var VALIDATED = "VALIDATED";
var REFUSED = "REFUSED";
var READY_TO_ASSIGN = "READY_TO_ASSIGN";
var BROUILLON = "Brouillon";
var RELECTURE_EN_COURS = "Relecture en cours";
var VALIDEE = "Valid\xE9e";
var REFUSEE = "Refus\xE9e";
var PRETE_POUR_AFFECTATION = "Pr\xEAte pour affectation";
var statusLabels = /* @__PURE__ */ new Map([
  [DRAFT, BROUILLON],
  [IN_REVIEW, RELECTURE_EN_COURS],
  [REFUSED, REFUSEE],
  [VALIDATED, VALIDEE],
  [READY_TO_ASSIGN, PRETE_POUR_AFFECTATION]
]);

// src/category.ts
var STATIQUE = "STATIQUE";
var BAR = "BAR";
var MANUTENTION = "MANUTENTION";
var FUN = "FUN";
var RELOU = "RELOU";
var categories = [STATIQUE, BAR, MANUTENTION, FUN, RELOU];
export {
  BAR,
  BROUILLON,
  DRAFT,
  FUN,
  IN_REVIEW,
  MANUTENTION,
  PRETE_POUR_AFFECTATION,
  READY_TO_ASSIGN,
  REFUSED,
  REFUSEE,
  RELECTURE_EN_COURS,
  RELOU,
  STATIQUE,
  VALIDATED,
  VALIDEE,
  categories,
  statusLabels
};
