var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/duration/duration.constant.ts
var HOURS_IN_DAY = 24;
var DAYS_IN_WEEK = 7;
var MINUTES_IN_HOUR = 60;
var MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
var ONE_SECOND_IN_MS = 1e3;
var ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
var QUARTER_IN_MS = 15 * ONE_MINUTE_IN_MS;
var ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * MINUTES_IN_HOUR;
var TWO_HOURS_IN_MS = 2 * ONE_HOUR_IN_MS;
var ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
var ONE_YEAR_IN_MS = ONE_DAY_IN_MS * 365;
var THIRTY_SECONDS_IN_MS = 30 * ONE_SECOND_IN_MS;
var ONE_WEEK_IN_MS = ONE_DAY_IN_MS * DAYS_IN_WEEK;
var ONE_DAY_IN_SECONDS = ONE_DAY_IN_MS / ONE_SECOND_IN_MS;
var ONE_WEEK_IN_SECONDS = 7 * ONE_DAY_IN_SECONDS;

// src/period/period.ts
var END_BEFORE_START_ERROR_MESSAGE = "La date de fin doit \xEAtre apr\xE8s la date de d\xE9but";
var NO_DURATION_ERROR_MESSAGE = "La p\xE9riode doit avoir une dur\xE9e";
var EndBeforeStart = class extends Error {
  static {
    __name(this, "EndBeforeStart");
  }
  constructor() {
    super(END_BEFORE_START_ERROR_MESSAGE);
  }
};
var Period = class _Period {
  static {
    __name(this, "Period");
  }
  start;
  end;
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }
  get hasDuration() {
    return this.start.getTime() !== this.end.getTime();
  }
  static init({ start, end }) {
    if (_Period.isEndBeforeStart({ start, end })) {
      throw new EndBeforeStart();
    }
    return new _Period(start, end);
  }
  static isValid(period) {
    return this.errors(period).length === 0;
  }
  static errors(period) {
    return _Period.isEndBeforeStart(period) ? [END_BEFORE_START_ERROR_MESSAGE] : _Period.init(period).hasDuration ? [] : [NO_DURATION_ERROR_MESSAGE];
  }
  static isEndBeforeStart({ start, end }) {
    return end.getTime() < start.getTime();
  }
  isOverlapping(otherPeriod) {
    return this.start.getTime() < otherPeriod.end.getTime() && this.end.getTime() > otherPeriod.start.getTime();
  }
  includes(otherPeriod) {
    return this.start.getTime() <= otherPeriod.start.getTime() && this.end.getTime() >= otherPeriod.end.getTime();
  }
  isIncluding(date) {
    const happenAfterStart = date.getTime() >= this.start.getTime();
    const happenBeforeEnd = date.getTime() < this.end.getTime();
    return happenAfterStart && happenBeforeEnd;
  }
  isMergableWith(otherPeriod) {
    return this.start.getTime() <= otherPeriod.end.getTime() && this.end.getTime() >= otherPeriod.start.getTime();
  }
  mergeWith(otherPeriod) {
    const start = new Date(
      Math.min(otherPeriod.start.getTime(), this.start.getTime())
    );
    const end = new Date(
      Math.max(otherPeriod.end.getTime(), this.end.getTime())
    );
    return new _Period(start, end);
  }
  splitOverlapping(otherPeriod) {
    const [firstStart, firstEnd, secondStart, secondEnd] = [
      this.start,
      this.end,
      otherPeriod.start,
      otherPeriod.end
    ].sort((a, b) => a.getTime() - b.getTime());
    const firstPeriod = _Period.init({ start: firstStart, end: firstEnd });
    const secondPeriod = _Period.init({ start: firstEnd, end: secondStart });
    const thirdPeriod = _Period.init({ start: secondStart, end: secondEnd });
    return [firstPeriod, secondPeriod, thirdPeriod].filter(
      (period) => period.hasDuration
    );
  }
  splitInto(parts) {
    if (parts <= 0) return [this];
    const totalDuration = this.end.getTime() - this.start.getTime();
    const partDuration = Math.floor(totalDuration / parts);
    return Array.from({ length: parts }, (_, index) => {
      const start = new Date(this.start.getTime() + index * partDuration);
      const isLastPart = index === parts - 1;
      const calculatedEnd = new Date(start.getTime() + partDuration);
      const end = isLastPart ? this.end : calculatedEnd;
      return _Period.init({ start, end });
    });
  }
  substract(otherPeriod) {
    const pastPeriod = _Period.init({
      start: this.start,
      end: otherPeriod.start
    });
    const futurPeriod = _Period.init({
      start: otherPeriod.end,
      end: this.end
    });
    return [pastPeriod, futurPeriod].filter((period) => period.hasDuration);
  }
  remove(otherPeriod) {
    if (!otherPeriod.isOverlapping(this)) {
      return [this];
    }
    return this.mergeWith(otherPeriod).substract(otherPeriod);
  }
  equals(otherPeriod) {
    const startAtSameTime = this.start.getTime() === otherPeriod.start.getTime();
    const endAtSameTime = this.end.getTime() === otherPeriod.end.getTime();
    return startAtSameTime && endAtSameTime;
  }
  static mergeContiguous(periods) {
    const sortedPeriods = this.sort(periods);
    return sortedPeriods.reduce(
      (mergedPeriods, currentPeriod) => {
        const lastMergedPeriod = mergedPeriods.at(mergedPeriods.length - 1);
        if (!lastMergedPeriod) return [currentPeriod];
        const isMergeable = lastMergedPeriod.isMergableWith(currentPeriod);
        if (isMergeable) {
          const mergedPeriod = lastMergedPeriod.mergeWith(currentPeriod);
          return [...mergedPeriods.slice(0, -1), mergedPeriod];
        }
        return [...mergedPeriods, currentPeriod];
      },
      []
    );
  }
  splitWithInterval(interval) {
    const intervalInMs = interval.inMilliseconds;
    const periodCount = Math.ceil(
      (this.end.getTime() - this.start.getTime()) / intervalInMs
    );
    return Array.from({ length: periodCount }, (_, index) => {
      const start = new Date(this.start.getTime() + index * intervalInMs);
      const end = new Date(start.getTime() + intervalInMs);
      return _Period.init({ start, end: end < this.end ? end : this.end });
    });
  }
  isInDay(day) {
    const dayString = OverDate.from(day).dateString;
    const dayStart = OverDate.init({ date: dayString, hour: 0, minute: 0 });
    const dayEnd = OverDate.from(dayStart.date.getTime() + ONE_DAY_IN_MS);
    return this.isOverlapping(
      _Period.init({ start: dayStart.date, end: dayEnd.date })
    );
  }
  toString() {
    const start = this.formatDate(this.start);
    const end = this.formatDate(this.end);
    return `${start} - ${end}`;
  }
  formatDate(date) {
    const displayOptions = {
      dateStyle: "long",
      timeStyle: "short"
    };
    return new Intl.DateTimeFormat("fr", displayOptions).format(date);
  }
  static sort(periods) {
    return periods.sort(
      (a, b) => a.start.getTime() - b.start.getTime() || a.end.getTime() - b.end.getTime()
    );
  }
  get duration() {
    const startTimestamp = this.start.getTime();
    const endTimestamp = this.end.getTime();
    return Duration.ms(endTimestamp - startTimestamp);
  }
  get id() {
    const startMinutes = Duration.ms(this.start.getTime()).inMinutes;
    const endMinutes = Duration.ms(this.end.getTime()).inMinutes;
    return `${startMinutes}-${endMinutes}`;
  }
};

// src/date/date.ts
var PARIS_TIMEZONE = {
  timeZone: "Europe/Paris"
};
var DISPLAY_TIMEZONE = {
  ...PARIS_TIMEZONE,
  timeZoneName: "short"
};
var DISPLAY_DATE = {
  ...PARIS_TIMEZONE,
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};
var DISPLAY_TIME = {
  ...PARIS_TIMEZONE,
  hour: "2-digit",
  minute: "2-digit"
};
var DISPLAY_DATE_TIME = {
  ...DISPLAY_DATE,
  ...DISPLAY_TIME
};
var OverDate = class _OverDate {
  constructor(definition) {
    this.definition = definition;
  }
  static {
    __name(this, "OverDate");
  }
  static init(init) {
    const definition = _OverDate.defineFrom(init);
    return new _OverDate(definition);
  }
  static defineFrom(init) {
    const { date, hour, minute = 0 } = init;
    const [year, month, day] = date.split("-");
    const monthlyDate = { month, day };
    if (!isMonthlyDate(monthlyDate)) throw new Error();
    const hours = formatDateNumberValue(hour);
    const minutes = formatDateNumberValue(minute);
    const datetime = `${date}T${hours}:${minutes}`;
    const offset = extractOffset(new Date(datetime));
    return { year: +year, monthlyDate, minute, hour, offset };
  }
  static from(international) {
    const internationalDate = new Date(international);
    const [hourWithPad, minuteWithPad] = Intl.DateTimeFormat("fr", DISPLAY_TIME).format(internationalDate).split(":");
    const hour = +hourWithPad;
    const minute = +minuteWithPad;
    const [day, month, year] = Intl.DateTimeFormat("fr", DISPLAY_DATE).format(internationalDate).split("/");
    const monthlyDate = { month, day };
    if (!isMonthlyDate(monthlyDate) || !isHour(hour) || !isMinute(minute))
      throw new Error();
    const offset = extractOffset(internationalDate);
    return new _OverDate({ year: +year, monthlyDate, hour, minute, offset });
  }
  static fromLocal(local) {
    const year = local.getFullYear();
    const month = formatDateNumberValue(local.getMonth() + 1);
    const day = formatDateNumberValue(local.getDate());
    const monthlyDate = { month, day };
    const hour = local.getHours();
    const minute = local.getMinutes();
    if (!isMonthlyDate(monthlyDate) || !isHour(hour) || !isMinute(minute))
      throw new Error();
    const offset = extractOffset(local);
    return new _OverDate({ year, monthlyDate, hour, minute, offset });
  }
  static now() {
    return _OverDate.fromLocal(/* @__PURE__ */ new Date());
  }
  get date() {
    const { month, day } = this.definition.monthlyDate;
    const date = `${this.definition.year}-${month}-${day}`;
    const time = `${formatDateNumberValue(this.hour)}:${formatDateNumberValue(this.minute)}`;
    return /* @__PURE__ */ new Date(`${date}T${time}+${this.definition.offset}:00`);
  }
  get dateString() {
    const { month, day } = this.definition.monthlyDate;
    return `${this.definition.year}-${month}-${day}`;
  }
  get year() {
    return this.definition.year;
  }
  get monthlyDate() {
    return this.definition.monthlyDate;
  }
  get hour() {
    return this.definition.hour;
  }
  get minute() {
    return this.definition.minute;
  }
  get timestamp() {
    return this.date.getTime();
  }
  get period() {
    const start = this.date;
    const end = new Date(this.timestamp + ONE_HOUR_IN_MS);
    return Period.init({ start, end });
  }
  isIncludedBy(periods) {
    return periods.some((period) => Period.init(period).isIncluding(this.date));
  }
  plus(duration) {
    return _OverDate.from(this.timestamp + duration.inMilliseconds);
  }
  minus(duration) {
    return _OverDate.from(this.timestamp - duration.inMilliseconds);
  }
};
var DATE_TEMPORAL_PARTS = 3;
function isDateString(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return false;
  return dateString.split("-").length === DATE_TEMPORAL_PARTS;
}
__name(isDateString, "isDateString");
function isMonthlyDate(monthlyDate) {
  return isMonth(monthlyDate.month) && isDayOf(monthlyDate.month, monthlyDate.day);
}
__name(isMonthlyDate, "isMonthlyDate");
function isMonth(month) {
  return month.length === 2 && +month > 0 && +month <= 12;
}
__name(isMonth, "isMonth");
function isDayOf(month, day) {
  const maxDayOfTheMonth = month === "02" ? 28 : ["04", "06", "09", "11"].includes(month) ? 30 : 31;
  return day.length === 2 && +day > 0 && +month <= maxDayOfTheMonth;
}
__name(isDayOf, "isDayOf");
function isHour(hour) {
  return hour >= 0 && hour < 24;
}
__name(isHour, "isHour");
function isMinute(minute) {
  return minute >= 0 && minute < 60;
}
__name(isMinute, "isMinute");
function isOffset(offset) {
  return offset === "01" || offset === "02";
}
__name(isOffset, "isOffset");
function extractOffset(date) {
  const parisTime = new Intl.DateTimeFormat("fr", DISPLAY_TIMEZONE).formatToParts(date).find((value) => value.type === "timeZoneName")?.value;
  const offsetSearched = parisTime?.match(/UTC[+-](\d)/);
  const offset = (offsetSearched?.at(1) ?? "").padStart(2, "0");
  if (!isOffset(offset)) throw new Error(offset);
  return offset;
}
__name(extractOffset, "extractOffset");

// src/date/format-date.utils.ts
function formatDateWithMinutes(date) {
  return new Intl.DateTimeFormat("fr", DISPLAY_DATE_TIME).format(
    new Date(date)
  );
}
__name(formatDateWithMinutes, "formatDateWithMinutes");
function formatDate(date) {
  return new Intl.DateTimeFormat("fr", DISPLAY_DATE).format(new Date(date));
}
__name(formatDate, "formatDate");
function formatMonthWithYear(date) {
  const displayOptions = {
    year: "numeric",
    month: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatMonthWithYear, "formatMonthWithYear");
function formatLocalDateTime(date) {
  const dateTime = new Intl.DateTimeFormat("fr", DISPLAY_DATE_TIME);
  const [day, month, year, hours, minutes] = dateTime.formatToParts(new Date(date)).filter((part) => part.type !== "literal").map((part) => part.value);
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
__name(formatLocalDateTime, "formatLocalDateTime");
function formatLocalDate(date) {
  const [day, month, year] = new Intl.DateTimeFormat("fr", DISPLAY_DATE).format(new Date(date)).split("/");
  return `${year}-${month}-${day}`;
}
__name(formatLocalDate, "formatLocalDate");
function formatDateWithExplicitMonth(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithExplicitMonth, "formatDateWithExplicitMonth");
function formatDateWithExplicitMonthAndDay(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithExplicitMonthAndDay, "formatDateWithExplicitMonthAndDay");
function formatDateDayName(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    weekday: "short"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateDayName, "formatDateDayName");
function formatDateDayFullName(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    weekday: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateDayFullName, "formatDateDayFullName");
function formatDateDayNumber(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    day: "numeric"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateDayNumber, "formatDateDayNumber");
function formatDateToHumanReadable(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    dateStyle: "long",
    timeStyle: "short"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateToHumanReadable, "formatDateToHumanReadable");
function formatDateDayToHumanReadable(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    month: "long",
    day: "numeric",
    weekday: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateDayToHumanReadable, "formatDateDayToHumanReadable");
function formatDateWithHoursAndMinutesOnly(date) {
  return new Intl.DateTimeFormat("fr", DISPLAY_TIME).format(new Date(date));
}
__name(formatDateWithHoursAndMinutesOnly, "formatDateWithHoursAndMinutesOnly");
function formatDateNumberValue(value) {
  return value.toString().padStart(2, "0");
}
__name(formatDateNumberValue, "formatDateNumberValue");

// src/duration/duration.ts
var Duration = class _Duration {
  constructor(milliseconds) {
    this.milliseconds = milliseconds;
  }
  static {
    __name(this, "Duration");
  }
  static ms(milliseconds) {
    return new _Duration(milliseconds);
  }
  static get ONE_HOUR() {
    return new _Duration(ONE_HOUR_IN_MS);
  }
  static get ONE_DAY() {
    return new _Duration(ONE_DAY_IN_MS);
  }
  static get ONE_WEEK() {
    return new _Duration(ONE_WEEK_IN_MS);
  }
  static hours(hours) {
    return new _Duration(hours * ONE_HOUR_IN_MS);
  }
  get inSeconds() {
    return Math.ceil(this.milliseconds / ONE_SECOND_IN_MS);
  }
  get inMinutes() {
    return Math.ceil(this.milliseconds / ONE_MINUTE_IN_MS);
  }
  get inHours() {
    return Math.ceil(this.milliseconds / ONE_HOUR_IN_MS);
  }
  get inMilliseconds() {
    return this.milliseconds;
  }
  canBeDividedBy(divider) {
    return this.milliseconds % divider.milliseconds === 0;
  }
  toString() {
    const hours = Math.floor(this.milliseconds / ONE_HOUR_IN_MS);
    const minutes = Math.floor(
      this.milliseconds % ONE_HOUR_IN_MS / ONE_MINUTE_IN_MS
    );
    const formattedHours = hours.toString();
    const formattedMinutes = formatDateNumberValue(minutes);
    return `${formattedHours}h${formattedMinutes}`;
  }
};

// src/date/date.utils.ts
function getHourDiff(start, end) {
  const diff = end.getTime() - start.getTime();
  return diff / ONE_HOUR_IN_MS;
}
__name(getHourDiff, "getHourDiff");
function roundMinutes(date, round) {
  if (!date) return null;
  const minutes = date.getMinutes();
  if (minutes % round === 0) return date;
  const minutesRounded = Math.round(minutes / round) * round;
  date.setMinutes(minutesRounded);
  return date;
}
__name(roundMinutes, "roundMinutes");
function computeTomorrowDate(date) {
  return new Date(date.getTime() + ONE_DAY_IN_MS);
}
__name(computeTomorrowDate, "computeTomorrowDate");

// src/edition/edition.ts
var BASE_EDITION_STARTS = /* @__PURE__ */ new Date("2023-09-01");
var BASE_EDITION = 49;
var Edition = class _Edition {
  static {
    __name(this, "Edition");
  }
  static get current() {
    return this.findEdition(/* @__PURE__ */ new Date());
  }
  static findEdition(date) {
    const dateTimestamp = date.getTime();
    const baseEditionTimestamp = BASE_EDITION_STARTS.getTime();
    const durationAfterBaseEdition = dateTimestamp - baseEditionTimestamp;
    const durationInYears = durationAfterBaseEdition / ONE_YEAR_IN_MS;
    const editionsAfterBaseEdition = Math.floor(durationInYears);
    return BASE_EDITION + editionsAfterBaseEdition;
  }
  static on(date) {
    return _Edition.findEdition(date);
  }
};
export {
  BASE_EDITION_STARTS,
  DAYS_IN_WEEK,
  Duration,
  Edition,
  EndBeforeStart,
  HOURS_IN_DAY,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  ONE_DAY_IN_MS,
  ONE_DAY_IN_SECONDS,
  ONE_HOUR_IN_MS,
  ONE_MINUTE_IN_MS,
  ONE_SECOND_IN_MS,
  ONE_WEEK_IN_MS,
  ONE_WEEK_IN_SECONDS,
  ONE_YEAR_IN_MS,
  OverDate,
  Period,
  QUARTER_IN_MS,
  THIRTY_SECONDS_IN_MS,
  TWO_HOURS_IN_MS,
  computeTomorrowDate,
  formatDate,
  formatDateDayFullName,
  formatDateDayName,
  formatDateDayNumber,
  formatDateDayToHumanReadable,
  formatDateNumberValue,
  formatDateToHumanReadable,
  formatDateWithExplicitMonth,
  formatDateWithExplicitMonthAndDay,
  formatDateWithHoursAndMinutesOnly,
  formatDateWithMinutes,
  formatLocalDate,
  formatLocalDateTime,
  formatMonthWithYear,
  getHourDiff,
  isDateString,
  isHour,
  isMinute,
  roundMinutes
};
