import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_pHrIO7IezE6wa6AJLNRqZnPpOHNNE6JqVm232Y1x6Cs from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QaI4dKFwEEndZEm7uRME78JbhIUjqVjx7cf8rc9E5Ts from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router__qFnARAl_FEgarxmKrDWdbIe_3NzClm1oeGKoABdJBY from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_d7eyVIsdEnep_6qHdi_5Hnt08UaCw8VC177WZOCrhoA from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IYKW2U2Za1cEXmxiHGPIOWYw8EDathnPBeRAysDRe_8 from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eaTuLM4Aon3tsthFceWW9wPsUS5skjBPHWOU_2FUfK4 from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ILbjsSBKPHBy6GeilYiHrKA7Vk_Rtdjec6sZ2ht0uKk from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_NxvYI7BShQc98YBi7wuSSfr8Ji7oEZt3Kkin9w7yDfY from "/overbookd/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_49a27540960e83568c47603dbe4ad4e2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/overbookd/apps/web/.nuxt/pwa-icons-plugin.ts";
import chartjs_client_R6gnWpzFdyEMU9cxIYCCGwOTsIRE1Bhl2juM5Zi8dco from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_85mzCrOAE8BJk1F6KIOF8KFwn1OetYbSXb7mMsS9eHo from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_yOgSoPgC7n9Q5KK4TNbkJXl9kqFsi0ZkbUstVpwfajY from "/overbookd/apps/web/plugins/vuetify.client.ts";
import pwa_client_Sm2LWFPZScNUtI_Xc_tF_fFyC0bSR_tzzB0g4d2J2qw from "/overbookd/node_modules/.pnpm/@vite-pwa+nuxt@0.10.8_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_sass@1._60476d9ac8a242822ee68d291e173654/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  revive_payload_client_pHrIO7IezE6wa6AJLNRqZnPpOHNNE6JqVm232Y1x6Cs,
  unhead_QaI4dKFwEEndZEm7uRME78JbhIUjqVjx7cf8rc9E5Ts,
  router__qFnARAl_FEgarxmKrDWdbIe_3NzClm1oeGKoABdJBY,
  payload_client_d7eyVIsdEnep_6qHdi_5Hnt08UaCw8VC177WZOCrhoA,
  navigation_repaint_client_IYKW2U2Za1cEXmxiHGPIOWYw8EDathnPBeRAysDRe_8,
  check_outdated_build_client_eaTuLM4Aon3tsthFceWW9wPsUS5skjBPHWOU_2FUfK4,
  chunk_reload_client_ILbjsSBKPHBy6GeilYiHrKA7Vk_Rtdjec6sZ2ht0uKk,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_NxvYI7BShQc98YBi7wuSSfr8Ji7oEZt3Kkin9w7yDfY,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  chartjs_client_R6gnWpzFdyEMU9cxIYCCGwOTsIRE1Bhl2juM5Zi8dco,
  vue_html_secure_client_85mzCrOAE8BJk1F6KIOF8KFwn1OetYbSXb7mMsS9eHo,
  vuetify_client_yOgSoPgC7n9Q5KK4TNbkJXl9kqFsi0ZkbUstVpwfajY,
  pwa_client_Sm2LWFPZScNUtI_Xc_tF_fFyC0bSR_tzzB0g4d2J2qw
]