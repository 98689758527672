
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as sgLOso1Hy_QxcEhx3QYEN9_QcsVUNJZg5HwFm2suoCcJ0Meta } from "/overbookd/apps/web/pages/sg.vue?macro=true";
import { default as indexzlid0_Jy6b4dLcjs7MZg5dHJPAObS_45MOZR7Mh0_45zHTsMeta } from "/overbookd/apps/web/pages/index.vue?macro=true";
import { default as login6rAk_0PcDPjXV90J3EWOY1cbIyKs6yj9zLPK1HpjQnsMeta } from "/overbookd/apps/web/pages/login.vue?macro=true";
import { default as statspwCc6fFI1Xi41wXCp_hWvUlza6lkx43KLS3vCqNpQtMMeta } from "/overbookd/apps/web/pages/stats.vue?macro=true";
import { default as _420K3oLKqli4yYPNGi6_45pGA9DMwH_45OKCnjmAlxGBkdiSWQMeta } from "/overbookd/apps/web/pages/ft/420.vue?macro=true";
import { default as _91id_93Xn92HFgRZ1RbMWJsgNx0XvQ9tGG8A2Lm07Ogjw8DgqYMeta } from "/overbookd/apps/web/pages/fa/[id].vue?macro=true";
import { default as _91id_93ztz27RtDzkyxdEVAdzHyUNBz_mvBrLIzLKlO0aP83gcMeta } from "/overbookd/apps/web/pages/ft/[id].vue?macro=true";
import { default as indexiDSdtJd_45HV1RNnFQNAySHOPjg5Qg1FfSchQmw58o0QoMeta } from "/overbookd/apps/web/pages/fa/index.vue?macro=true";
import { default as index87VXb_45kcB56_Shtfg7HHWW1OHgebFZnmIQwe1iNi598Meta } from "/overbookd/apps/web/pages/ft/index.vue?macro=true";
import { default as registerSt_45dimDbU_45BZZ2Sxrq2VE0A24iyfYWfqjozUiG3xfe0Meta } from "/overbookd/apps/web/pages/register.vue?macro=true";
import { default as timelineArax3_XloNHyu3JgIoCkcPS73r4_DO31L8wBp8KoTgIMeta } from "/overbookd/apps/web/pages/timeline.vue?macro=true";
import { default as need_45helpLYoVmsnD8AC3CU3Jagcs3ltn2RORa913Nggij_MgSGIMeta } from "/overbookd/apps/web/pages/need-help.vue?macro=true";
import { default as orga_45needfgOwRzC4vBoL_45BI6zKltwMOEm_q_45F24h_qbiabgtY98Meta } from "/overbookd/apps/web/pages/orga-need.vue?macro=true";
import { default as volunteersW0vWm3dXVknm69cUT0LH1sSrIvTeIenNQ_tPgwLwc5kMeta } from "/overbookd/apps/web/pages/volunteers.vue?macro=true";
import { default as shared_45mealsGOL0YmPZ_45OmlaPV18hHBu3ZubOTkld0kRTLYL7fAOIIMeta } from "/overbookd/apps/web/pages/shared-meals.vue?macro=true";
import { default as transactions1jPIdrdVbkFX7NNeEKPtR_45nBcVE84cWogmGnxp8vWGQMeta } from "/overbookd/apps/web/pages/transactions.vue?macro=true";
import { default as configurationv8a4nIRkfLdHunz7g1Mx_IWZ88W8q0ATLjcJqpMeZ2oMeta } from "/overbookd/apps/web/pages/configuration.vue?macro=true";
import { default as contributionsCXiOqZw0RN5TDFPigGhglosMTFow1yAz2XP1Ai748pcMeta } from "/overbookd/apps/web/pages/contributions.vue?macro=true";
import { default as to_45publish_45KrGQup5yiCu6Mdo_YkymaTDIOJo6FnJOVG5bNVVsxgMeta } from "/overbookd/apps/web/pages/fa/to-publish.vue?macro=true";
import { default as _91token_93kVWqZMbtqFPu_1LxZU3p7tfXNE4aTb3SJTxCxOLWxeAMeta } from "/overbookd/apps/web/pages/reset/[token].vue?macro=true";
import { default as catalogZkjgr2x8G94eOjTu_45OHuD72kFaiWqsctp2I69Il0MjAMeta } from "/overbookd/apps/web/pages/signa/catalog.vue?macro=true";
import { default as availabilitiesYHdYge_CaDAWmODgaEQ1hiyH5fVBBihVRLizYTH9MGQMeta } from "/overbookd/apps/web/pages/availabilities.vue?macro=true";
import { default as indexkcGt7WJlyCU8p0ia4ath6g_45__7SC0JR8LaT3CYtB3CQMeta } from "/overbookd/apps/web/pages/planning/index.vue?macro=true";
import { default as locationFbk7MGT9IGKAlrRX3VmFZRbkOZV_455W5ZGVIc7yfdGaYMeta } from "/overbookd/apps/web/pages/signa/location.vue?macro=true";
import { default as periods2QcQMuCN9t9LuZlCpEiqQInpU6_454O4X9hzl_j_455RHZMMeta } from "/overbookd/apps/web/pages/charisma/periods.vue?macro=true";
import { default as catalog_450JgpB8TNzMvxbXkcShx4z0WCaHNT2Q3DQPlzanyf4QMeta } from "/overbookd/apps/web/pages/logistic/catalog.vue?macro=true";
import { default as _91userId_93v1eEu8krAjk_EuNhZivpJeGUQgBc38f_1rvsPcPSOC8Meta } from "/overbookd/apps/web/pages/planning/[userId].vue?macro=true";
import { default as dashboardi4xSQC_bcDW_2O4nW47_45gTYsjksm2k_45eV6vMlowtuPAMeta } from "/overbookd/apps/web/pages/logistic/dashboard.vue?macro=true";
import { default as inventoryD_454C6ixgfN9MS9laFB7nnO_45l_45qA34_oLAIf2jf4vMEYMeta } from "/overbookd/apps/web/pages/logistic/inventory.vue?macro=true";
import { default as my_45personal_45account48J0tqbZGzDsu0KbkMjQwhPmdQOKAFYa5CB9PwZq0cwMeta } from "/overbookd/apps/web/pages/my-personal-account.vue?macro=true";
import { default as staffdHRk3lygUo_kqX_45uKL2ei5Zld_zf_45b6Yor8Ax97cXBkMeta } from "/overbookd/apps/web/pages/registrations/staff.vue?macro=true";
import { default as orga_45taskH3OvVSJBOkzgnip4NsqtRZ3FUZrwS1TEMAVZwj18U3sMeta } from "/overbookd/apps/web/pages/assignment/orga-task.vue?macro=true";
import { default as task_45orgaTMPChmySOD6RDkmi3NsYF8IK8TjgBy9ui_BH_odRLIsMeta } from "/overbookd/apps/web/pages/assignment/task-orga.vue?macro=true";
import { default as listHYNti15tHUVyIsa7eWMeyYoFw7UmxaYp7M_45lFe_45Z_0kMeta } from "/overbookd/apps/web/pages/charisma/events/list.vue?macro=true";
import { default as securityj20FnkK7T2sC5jrjxLMUtPYuwmDCb_lOB6z0GTqGmEgMeta } from "/overbookd/apps/web/pages/fa/dashboard/security.vue?macro=true";
import { default as indexLM43_45rwszDlzITz8vU7mn7zOEI6AqSuG5fASSiN_450tAMeta } from "/overbookd/apps/web/pages/logistic/borrow/index.vue?macro=true";
import { default as manage5dehjAARPm7mwrggbAok2DoCrG_T6Tj8mMhk03zoy38Meta } from "/overbookd/apps/web/pages/charisma/events/manage.vue?macro=true";
import { default as index2i8EXzxK2EtueUt9WfmJ1uuizBgxRdF3OPuf7Oep2EAMeta } from "/overbookd/apps/web/pages/logistic/purchase/index.vue?macro=true";
import { default as volunteerJ5XXrM4h8VOpV77qovXXiIiuUQ1hvJVe7ItfZ_2WHr8Meta } from "/overbookd/apps/web/pages/registrations/volunteer.vue?macro=true";
import { default as _91borrowId_93YhBCkfrvqS365xgi_453b4xzWLXDUm_45NPo0A1_1GXuEgIMeta } from "/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue?macro=true";
import { default as _91purchaseId_93z_SCc_iky_45BY2xGSvnqPkTv9NL8S14Kd6LrUBfHCfIIMeta } from "/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue?macro=true";
export default [
  {
    name: "sg",
    path: "/sg",
    component: () => import("/overbookd/apps/web/pages/sg.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/overbookd/apps/web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login6rAk_0PcDPjXV90J3EWOY1cbIyKs6yj9zLPK1HpjQnsMeta || {},
    component: () => import("/overbookd/apps/web/pages/login.vue")
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/overbookd/apps/web/pages/stats.vue")
  },
  {
    name: "ft-420",
    path: "/ft/420",
    component: () => import("/overbookd/apps/web/pages/ft/420.vue")
  },
  {
    name: "fa-id",
    path: "/fa/:id()",
    component: () => import("/overbookd/apps/web/pages/fa/[id].vue")
  },
  {
    name: "ft-id",
    path: "/ft/:id()",
    component: () => import("/overbookd/apps/web/pages/ft/[id].vue")
  },
  {
    name: "fa",
    path: "/fa",
    component: () => import("/overbookd/apps/web/pages/fa/index.vue")
  },
  {
    name: "ft",
    path: "/ft",
    component: () => import("/overbookd/apps/web/pages/ft/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerSt_45dimDbU_45BZZ2Sxrq2VE0A24iyfYWfqjozUiG3xfe0Meta || {},
    component: () => import("/overbookd/apps/web/pages/register.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/overbookd/apps/web/pages/timeline.vue")
  },
  {
    name: "need-help",
    path: "/need-help",
    component: () => import("/overbookd/apps/web/pages/need-help.vue")
  },
  {
    name: "orga-need",
    path: "/orga-need",
    component: () => import("/overbookd/apps/web/pages/orga-need.vue")
  },
  {
    name: "volunteers",
    path: "/volunteers",
    component: () => import("/overbookd/apps/web/pages/volunteers.vue")
  },
  {
    name: "shared-meals",
    path: "/shared-meals",
    component: () => import("/overbookd/apps/web/pages/shared-meals.vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/overbookd/apps/web/pages/transactions.vue")
  },
  {
    name: "configuration",
    path: "/configuration",
    component: () => import("/overbookd/apps/web/pages/configuration.vue")
  },
  {
    name: "contributions",
    path: "/contributions",
    component: () => import("/overbookd/apps/web/pages/contributions.vue")
  },
  {
    name: "fa-to-publish",
    path: "/fa/to-publish",
    component: () => import("/overbookd/apps/web/pages/fa/to-publish.vue")
  },
  {
    name: "reset-token",
    path: "/reset/:token()",
    meta: _91token_93kVWqZMbtqFPu_1LxZU3p7tfXNE4aTb3SJTxCxOLWxeAMeta || {},
    component: () => import("/overbookd/apps/web/pages/reset/[token].vue")
  },
  {
    name: "signa-catalog",
    path: "/signa/catalog",
    component: () => import("/overbookd/apps/web/pages/signa/catalog.vue")
  },
  {
    name: "availabilities",
    path: "/availabilities",
    component: () => import("/overbookd/apps/web/pages/availabilities.vue")
  },
  {
    name: "planning",
    path: "/planning",
    component: () => import("/overbookd/apps/web/pages/planning/index.vue")
  },
  {
    name: "signa-location",
    path: "/signa/location",
    component: () => import("/overbookd/apps/web/pages/signa/location.vue")
  },
  {
    name: "charisma-periods",
    path: "/charisma/periods",
    component: () => import("/overbookd/apps/web/pages/charisma/periods.vue")
  },
  {
    name: "logistic-catalog",
    path: "/logistic/catalog",
    component: () => import("/overbookd/apps/web/pages/logistic/catalog.vue")
  },
  {
    name: "planning-userId",
    path: "/planning/:userId()",
    component: () => import("/overbookd/apps/web/pages/planning/[userId].vue")
  },
  {
    name: "logistic-dashboard",
    path: "/logistic/dashboard",
    component: () => import("/overbookd/apps/web/pages/logistic/dashboard.vue")
  },
  {
    name: "logistic-inventory",
    path: "/logistic/inventory",
    component: () => import("/overbookd/apps/web/pages/logistic/inventory.vue")
  },
  {
    name: "my-personal-account",
    path: "/my-personal-account",
    component: () => import("/overbookd/apps/web/pages/my-personal-account.vue")
  },
  {
    name: "registrations-staff",
    path: "/registrations/staff",
    component: () => import("/overbookd/apps/web/pages/registrations/staff.vue")
  },
  {
    name: "assignment-orga-task",
    path: "/assignment/orga-task",
    component: () => import("/overbookd/apps/web/pages/assignment/orga-task.vue")
  },
  {
    name: "assignment-task-orga",
    path: "/assignment/task-orga",
    component: () => import("/overbookd/apps/web/pages/assignment/task-orga.vue")
  },
  {
    name: "charisma-events-list",
    path: "/charisma/events/list",
    component: () => import("/overbookd/apps/web/pages/charisma/events/list.vue")
  },
  {
    name: "fa-dashboard-security",
    path: "/fa/dashboard/security",
    component: () => import("/overbookd/apps/web/pages/fa/dashboard/security.vue")
  },
  {
    name: "logistic-borrow",
    path: "/logistic/borrow",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/index.vue")
  },
  {
    name: "charisma-events-manage",
    path: "/charisma/events/manage",
    component: () => import("/overbookd/apps/web/pages/charisma/events/manage.vue")
  },
  {
    name: "logistic-purchase",
    path: "/logistic/purchase",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/index.vue")
  },
  {
    name: "registrations-volunteer",
    path: "/registrations/volunteer",
    component: () => import("/overbookd/apps/web/pages/registrations/volunteer.vue")
  },
  {
    name: "logistic-borrow-borrowId",
    path: "/logistic/borrow/:borrowId()",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue")
  },
  {
    name: "logistic-purchase-purchaseId",
    path: "/logistic/purchase/:purchaseId()",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue")
  }
]